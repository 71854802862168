import { Carousal, DownloadButtonData, MainDescriptionProps, WinningText } from '../../../types/types';

export const processMainDescriptionData = (data: any, page: string): MainDescriptionProps => {


	const myData = data.allWpPage.edges[0].node[page][`${page}Herosection`];
	const buttonData = myData?.downloadbuttondata;

	const downloadButtonData: DownloadButtonData = {
		placeholderText: buttonData?.placeholdertext,
		button: {
			buttonColor: buttonData?.button?.buttoncolor,
			buttonAndroidImage: {
				imageFile: buttonData?.buttonandroidimage.imagefile?.localFile?.childImageSharp?.gatsbyImageData,
				altText: buttonData?.buttonandroidimage?.alttext
			},
			buttonIosImage: {
				imageFile: buttonData?.buttoniosimage?.imagefile?.localFile?.childImageSharp?.gatsbyImageData,
				altText: buttonData?.buttoniosimage?.alttext
			},
			buttonText: { ...buttonData?.button?.buttontext },
			buttonAndroidLink: buttonData?.buttonandroidlink,
			buttonIosLink: buttonData?.buttonioslink
		}
	};
	const winningText: WinningText = {
		text: myData?.imagewithtext?.description,
		image: { imageFile: myData?.imagewithtext?.image?.imagefile?.localFile?.url, altText: myData?.imagewithtext?.image?.alttext },
		text2: myData?.imagewithtext?.description2
	};
	const imageCarousal: Carousal = {
		scrollTimer: myData?.imageslider?.scrolltime,
		imageArray: myData?.imageslider?.sliders?.map((slider, index) => {
			return {
				imageFile: slider?.image?.imagefile?.localFile?.childImageSharp?.gatsbyImageData,
				altText: slider?.image?.alttext
			};
		})
	};
	const mainDescriptionData: MainDescriptionProps = {
		backgroundImage: myData.background.image?.localFile?.childImageSharp?.gatsbyImageData,
		...(downloadButtonData.placeholderText && { downloadButtonData }),
		...(winningText.text && { winningText }),
		...(winningText.text2 && { winningText }),
		...(myData.smherosectionbanner?.imagefile && {
			smHeroSectionBanner: {
				imageFile: myData.smherosectionbanner?.imagefile?.localFile?.childImageSharp?.gatsbyImageData,
				altText: myData.smherosectionbanner?.alttext
			}
		}),
		mainImage: myData.mainimage?.localFile?.childImageSharp?.gatsbyImageData,
		titleText: myData.titletext,
		subtitleText: myData.subtitletext,
		imageSlider: imageCarousal,
		backgroundColor: myData.background.color
	};
	return mainDescriptionData;
};

export default processMainDescriptionData;
