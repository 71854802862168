import React from "react";
import "./scrollMouseIcon.modules.css"

const ScrollMouseIcon = () => {
  const landscape = window.matchMedia("(orientation: landscape)").matches;

  return(
    <>
      <div className={`slider-scroll-container ${landscape? 'landscape-active' : ''}`}>
        <div className="slider-scroll-icon">
          <div className="arrow-down-icon"></div>
        </div>
      </div>
    </>
  );
};
export default ScrollMouseIcon;