import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';


import { WinningText } from '../../../types/types';

const WinningTextComponent = (props: WinningText) => {
	return (
		<div className='hero-section-winning-text-container'>
			{props.text && (
				<p style={{ color: props.text.color }} className='hero-section-winning-text-field hero-p-tag'>
					{props?.text?.text}
				</p>
			)}
			{props.image && <StaticImage src={"https://assets.zupee.com/downloads/assets/seperation-icon.webp"} alt={props.image.altText} className='hero-section-winning-text-gif' />}
			{props.text2 && (
				<p style={{ color: props.text2.color }} className='hero-section-winning-text-field hero-p-tag'>
					{props?.text2?.text}
				</p>
			)}
		</div>
	);
};

export default WinningTextComponent;
